/* eslint-disable no-undef */
import React from 'react';
import {RadioButtonGroup} from "./styles";
import PropTypes from "prop-types";
import { withTranslation } from 'react-i18next';

class RadioBtn extends React.Component{

  selectMany = () => {
    const { handler, id } = this.props;
    handler(id);
  }

  render() {
    const {challengeLeaderBoard, userBiometric, name, isSpouseDependent, companyFeature, billingCards,t, challengeCreate, createSurvey, style} = this.props;
    return (
      <RadioButtonGroup disabled={this.props.disabled} challengeLeaderBoard={challengeLeaderBoard} isSpouseDependent={isSpouseDependent} userBiometric={userBiometric} billingCards={billingCards} challengeCreate={challengeCreate} createSurvey={createSurvey} style={style}>
        <input disabled={this.props.disabled} id={this.props.id} onChange={(e) => companyFeature ? this.selectMany(e) : this.props.handler(e)} value={this.props.value} type="radio" checked={this.props.isChecked} name={name}/>
        <label htmlFor={this.props.id}>{t(this.props.label)}</label>
      </RadioButtonGroup>
    );
  }
}

RadioBtn.propTypes = {
  handler: PropTypes.func.isRequired,
  value: PropTypes.any,
  label:  PropTypes.string,
  isChecked: PropTypes.bool,
  id: PropTypes.string,
  challengeLeaderBoard: PropTypes.bool,
  userBiometric: PropTypes.number,
  isSpouseDependent: PropTypes.number,
  name: PropTypes.string,
  selectMany: PropTypes.func,
  companyFeature: PropTypes.number,
  billingCards: PropTypes.bool,
  t:PropTypes.func,
  disabled:PropTypes.bool,
  challengeCreate: PropTypes.bool,
  createSurvey:PropTypes.bool,
  style : PropTypes.any
};

RadioBtn.defaultProps = {
  challengeLeaderBoard: false,
  isSpouseDependent: 0
};

export default (withTranslation()( RadioBtn));