import styled, {css} from 'styled-components';

const CalendarDashboard = styled.div`
  width: 100%;
  float: left;
  display: flex;
  .calendarFlex {
    float: left;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0px;
  }
  .calendarBox {
    width: 100%;
    //height: 326px;
    margin: 0 auto;
    background-color: white;
    box-shadow: 1px 1.732px 46px rgba(0,0,0,0.1);
    .margin {
      margin-top: 30px;
      width: 100%;
      @media(max-width: 600px) {
        margin-left: 0px;
        padding: 0px 10px;
      }
    }
  }
`;
const CalendarFlex = styled.div`
  float: left;
  margin-top: 15px;
  margin-bottom: 15px;
  max-width: ${({ maxWidth }) => maxWidth && maxWidth};
  @media(max-width: 600px) {
    width: 100%;
    margin-left: 0px;
    padding: 0px;
  }
  .overview {
    width: 100%;
    padding: 0px 13px;
    margin: 0 auto;
    @media(max-width: 400px) {
      width: 280px;
      margin: 0px;
      padding: 0px;
    }
    .info {
      padding-bottom: 8px;
      display: flex;
      font-size: 14px;
      letter-spacing: 0px;
      line-height: 24px;
      color: #999999;
      font-weight: 400;
      width: 100%;
      .track {
        width: 50%;
        > span {
          color: #159fca;
          font-weight: bold;
        }
      }
      .remaining {
        width: 50%;
        text-align: right;
        > span {
          color: #159fca;
          font-weight: bold;
        }
      }
    }
  }
  .weeklyDashboard {
    width: 100%;
    padding: 20px 13px 0px;
    margin: 0 auto;
    @media(max-width: 400px) {
      width: 280px;
      margin: 0px;
      padding: 0px;
    }
  }
  .progressBar {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-around;
  }
  .font {
    font-size: 14px;
    letter-spacing: 0px;
    color: #999999;
    font-weight: bold;
  }
  .textContainer {
    width: calc(100% /3);
    text-align: center;
    color: #a6a6a7;
  }
  .textBottomContainer{
    width: 50%
    text-align: center;
    color: #a6a6a7;
  }
`;

const DayRow = styled.div`
  float: left;
  width: 100%;
  height: 35px;
  background-color: #EAEAEA;
`;

const DayDisplayer = styled.div`
  width: calc(100%/7);
  font-size: ${({ fontSize }) => fontSize ? fontSize : '16px'};
  line-height: 30px;
  background-color: white;
  color: ${({selectedbg, currentDate})=>currentDate ? '#fff' : selectedbg &&  '#000'};
  margin: auto !important;
  font-weight: 400;
  display: inline-block;
  text-align: center;
  padding-left: 0px;
  text-transform: Capitalize;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  line-height: 34px;
  cursor: pointer;
  color: #9c9c9c;
  // background-color: ${({selectedbg})=>selectedbg && 'rgb(30, 118, 171)'};
  .curveDay{
    margin-left: 12px;
    color: ${({selectedbg, currentDate, currentDateSelect}) => currentDateSelect?'white':currentDate ? '#fff' : selectedbg ? `#3a3a3a` : '#3a3a3a'};
    margin-top: 10px;
    line-height:${({selectedbg, currentDate}) => currentDate ? '34px' : selectedbg ? `27px` : '34px'};
    width:  ${({currentDate, selectedbg})=> selectedbg ? '30px' : currentDate ? '30px' : '30px'};
    height:  ${({currentDate, selectedbg})=> selectedbg ? '30px' : currentDate ? '30px' : '30px'};
    border-radius: 50%;
    border: ${({selectedbg, currentDate}) => currentDate ? 'none' : selectedbg ? `3px solid #44c7ae` : 'none'};
    background: ${({currentDate, selectedbg, currentDateSelect})=>currentDateSelect?'linear-gradient(90deg,rgb(0 177 188) -1.11%,rgb(117 217 163) 97.46%)':(selectedbg && currentDate)? 'linear-gradient(90deg, rgb(0 177 188) -1.11%, rgb(117 217 163) 97.46%)' :  
    currentDate ? 'linear-gradient(90deg, rgb(0 177 188) -1.11%, rgb(117 217 163) 97.46%)' : selectedbg ?' Transparent': ' #eaeaea'};
  }
  @media(max-width: 600px) {
    line-height: 22px;
  }
  ${({ disable }) => disable && css`
  border: solid #e1e1e3;
  border-width: 0 3px 3px 0;
  pointer-events: none;
  border-top-color: transparent;
  border-left-color: transparent;
`}
`; 

const CurrentTime = styled.div`
  float: left;
  width: 100%;
  text-align: center;
  margin-top: 0px;
  background-color: ${({bgColor}) => bgColor ? 'rgba(196, 196, 196, 0.2)' : 'none'};
  box-shadow: ${({bgColor}) => bgColor ? 'none' : 'none'};
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 15px 0px;
  .week {
    font-size: 20px;
  }
  > span {
    vertical-align: super;
    font-family: Rubik-Medium;
    font-size: 16px;
    color: #282C37;
    padding: 0 21px;
    min-width: 150px;
  }
  .corner{
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    // background-color: #69C2FF;
    border-radius: 50%;
    justify-content: center;
    cursor: pointer;
    >i{
      color: #fff;
    }
  }
  .yearContainer{
    width: 169px;
    height: 30px;
    padding: 5px;
    background: linear-gradient(90deg, rgba(0, 177, 188, 0.15)-1.11%, rgba(117, 217, 163, 0.15)97.46%);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Rubik-Medium';
    font-size: 18px;
    color: #0D4270;
    > div {
      color: #0D4270;
    }
  }
`;
export { DayDisplayer, CalendarFlex, DayRow, CalendarDashboard, CurrentTime};
