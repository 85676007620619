/* eslint-disable camelcase, no-undef,no-console */
import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { DayDisplayer, CalendarFlex, CalendarDashboard, DayRow, CurrentTime} from './styles';
//import { LeftArrow, RightArrow } from '../Content/MotivationalDashboard/styles';
import { getCountofCalendar } from '../../utils/methods';
import { isEmpty } from 'lodash';
import { withTranslation } from 'react-i18next';


class CustomCalender extends Component {
  constructor(props){
    super(props);
    this.state = {
      date: props.workoutDate,
      currentDate:moment(props.workoutDate).date() // 0
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if(this.props.workoutDate !== nextProps.workoutDate){
      this.setState({date: nextProps.workoutDate,})
    }
  }

  nextWeek = (date) => {
    let current = new Date();
    let month = current.getMonth();
    let newMonth = date.getMonth();
    let currentYear = current.getFullYear();
    let newYear = date.getFullYear();
    // {((newYear<currentYear)||(newMonth<month))&&
    // this.setState({
    //   date: moment(this.state.date).add(1, 'months'),
    //   currentDate:0
    // }, () => {
    //   this.props.onChange(this.state.date)
    // })
    // }
    if ((newYear < currentYear) || (newMonth < month)) {
      let selectedDate = this.state.currentDate;
      this.setState({
        date: moment(this.state.date).add(1, 'months'),
      }, () => {
        this.props.onChange(this.state.date);
        this.setState({ currentDate: selectedDate });
      });
    }
  };

  prevWeek = () => {
    const { minDate } = this.props;
    const { date } = this.state;
    const prevDate = moment(date).subtract(1, 'months');
    let selectedDate = this.state.currentDate;
    if (!prevDate.isBefore(minDate, 'month')) {
      this.setState({
        date: prevDate, // moment(this.state.date).subtract(1, 'months'),
        currentDate:0
      }, () => {
        this.props.onChange(this.state.date)
        this.setState({currentDate: selectedDate})
      })
    }
  };

  currentDateBackground =(day) =>{
    this.setState({ currentDate: day }) 
  }

  getCalendar = (date) => {
    let arr = [];
    let month = moment(date, 'MMMM').format('MMM');
    let year = moment(date).format('YYYY');
    let obj = getCountofCalendar(month, year);

    const registrationDate = moment(this.props.minDate);
    const registrationMonth = registrationDate.format('MMM');
    const registrationYear = registrationDate.format('YYYY');
    const registrationDay = registrationDate.date();

    for(let i=0;i<obj.emptyGrid;i++) {
      arr.push(<DayDisplayer key={`empty-${i}`}/>);//daysWorkoutList
    }
    for(let j=1;j<=obj.totalDay;j++) {
      const dayString = `${obj.month}-${j >= 10 ? j : `0${j}`}-${year}`;
      
      const isDisabled = (
        year === registrationYear &&
        month === registrationMonth &&
        j < registrationDay
      );
      
      arr.push(<DayDisplayer key={`date-${j}`} fontSize="16px" 
        currentDateSelect = {this.state.currentDate===j ? 1 : 0} 
        // currentDate={ moment(`${obj.month}-${j>=10?j:`0${j}`}-${year}`).format('MM-DD-YYYY') === moment(new Date()).format('MM-DD-YYYY') ? 1 : 0} 
        currentDate={moment(dayString, 'MMM-DD-YYYY').isSame(moment(), 'day') ? 1 : 0}
        selectedbg={!isEmpty(this.props.daysWorkoutList) ? this.props.daysWorkoutList[`${moment(`${obj.month}-${j>=10?j:`0${j}`}-${year}`).format('YYYY-MM-DD')}`] : 0} 
        // onClick={() => this.handleDateClick(dayString, j)} /* {this.props.onChange(`${obj.month}-${j>=10?j:`0${j}`}-${year}`);this.currentDateBackground(j)} */ ><div className={"curveDay"}>{j}</div></DayDisplayer>)
        onClick={() => {if(!isDisabled) { this.props.onChange(`${obj.month}-${j>=10?j:`0${j}`}-${year}`);this.currentDateBackground(j); this.setState({currentDate: j})}}} disablec={isDisabled} ><div className={"curveDay"}>{j}</div></DayDisplayer>)
    }
    return arr;
  };



  render() {
    const { date} = this.state;
    let startMonth = moment(date, 'MMMM').format('MMMM');
    let startYear = moment(date).format('YYYY');
    const dayDetails = [ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const lang= localStorage.getItem("lang");
    return (
      <CalendarDashboard>
        <div className="calendarBox">
          {
            <div className="calendarFlex">
              <CurrentTime >
                <div className="corner" style={{"margin-left":"-15px"}}>
                  <img src="/public/images/NewDashboardV2/arrowLeftt.png" alt="icon2" width="30" height="30" onClick={() => this.prevWeek()}/>
                </div>
                <div className="yearContainer">
                  { this.props.t(startMonth) +' '+ startYear }
                </div>
                <div className="corner" style={{"margin-right":"-15px"}}> 
                  <img src="/public/images/NewDashboardV2/arrowRightt.png" alt="icon2" width="30" height="30"  onClick={() => this.nextWeek(date)}/>
                </div>
              </CurrentTime>
              <CalendarFlex maxWidth="480px">
                <DayRow>
                  { dayDetails.map((dayDetails, index) => (
                    <DayDisplayer fontSize={lang=="fr"&&"12px"} key={index}>{this.props.t(dayDetails)}</DayDisplayer>
                  ))}
                </DayRow>
                {this.getCalendar(date)}
              </CalendarFlex>
            </div>
          }
        </div>
      </CalendarDashboard>
    );
  }
}

CustomCalender.propTypes = 
{
  workoutDate: PropTypes.string,
  storeValue: PropTypes.func,
  onChange: PropTypes.func,
  onViewChange: PropTypes.func,
  onActiveStartDateChange: PropTypes.func,
  value: PropTypes.object,
  maxDate: PropTypes.object ,
  minDate: PropTypes.object,
  daysWorkoutList: PropTypes.object,
  t:  PropTypes.func
};
export default ((withTranslation())(CustomCalender));